import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import Home from './Components/Home';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '@fontsource/lato'; // Defaults to weight 400.

class App extends Component   {

  render() {
    return (
      <div className='App'>
        {this.props.loaded ?
          <div id='contentwrapper' className='textpage'><Home /></div> :
          <div className='Loading-Circle'>
            <span className="questionnaire-name">CNT-Testcenter</span>
            <div><ClipLoader
              size={150}
              color={'#1F487C'}
              loading={true}
            />
            <br />
            Try to <Link to='/'>Sign In</Link> again
            </div>
          </div>
        }
      </div>
    );
  }
}


const mapStateToProps = state => {
  const { loaded } = state.appReducer;

  return {
    loaded
  };
};
export default connect(mapStateToProps)(App);
